import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ua/chapter_1/sub_2/slide1';
import Slide2 from 'src/slides/desktop/ua/chapter_1/sub_2/slide2';
import Slide3 from 'src/slides/desktop/ua/chapter_1/sub_2/slide3';
import Slide4 from 'src/slides/desktop/ua/chapter_1/sub_2/slide4';
import Slide5 from 'src/slides/desktop/ua/chapter_1/sub_2/slide5';
import Slide6 from 'src/slides/desktop/ua/chapter_1/sub_2/slide6';
import Slide7 from 'src/slides/desktop/ua/chapter_1/sub_2/slide7';
import Slide8 from 'src/slides/desktop/ua/chapter_1/sub_2/slide8';
import Slide9 from 'src/slides/desktop/ua/chapter_1/sub_2/slide9';
import Slide10 from 'src/slides/desktop/ua/chapter_1/sub_2/slide10';
import Slide11 from 'src/slides/desktop/ua/chapter_1/sub_2/slide11';

import WrapperMobile from 'src/slides/mobile/ua/chapter_1/sub_2/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />, <Slide6 />, <Slide7 />, <Slide8 />, <Slide9 />, <Slide10 />, <Slide11 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Наслідки Першої світової війни I Варшавська битва" lang="ua" description="Зоря комунізму і націоналізму, перший геноцид в XX столітті." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Наслідки Першої світової війни I Варшавська битва" lang="ua" description="Зоря комунізму і націоналізму, перший геноцид в XX столітті." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
